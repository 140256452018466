// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// The values below are defaults, they will be overriden at run time from environment variables (from docker compose, stack file etc)

export const environment = {
  production: true,
  baseUrl: 'NotRequired',
  authority: "https://login.microsoftonline.com/common/",
  clientID: 'NotRequired',
  version: "20241014.1",
  ppxPrivateApi: 'https://ppx-service-private.webuyanycar.com/',
  paymentUrl:'https://secure.worldpay.com/sso/public/auth/login.html?serviceIdentifier=merchantadmin',
  urls: {
  },
  sentry: {
    dsn: 'https://dd1f72ebeaf04c6d94d9e6baa7bc3335@sentry.io/1479676',
    environment: 'Production'
  },
  logToConsole: true
};
  
